import { useState } from 'react';
import { useTranslation } from "react-i18next";
import TermsModal from '../components/organisms/TermsModal';
import AuthHandler from "../services/common/auth-handler";

const authHandler = AuthHandler();
const Footer = () => {
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <footer>
            <div className="d-flex flex-md-row flex-column justify-content-between px-2 py-3">
                <div>
                    <a className="text-sm c-dark-blue px-3 fw-light" href={`https://www.datacm.com/${authHandler.getLanguage() + '/'}privacy-policy/`} target="_blank" rel="noreferrer">
                        {t("security")} / {t("privacy_policy")}
                    </a>
                    <span className="text-sm c-dark-blue fw-light link-span" onClick={() => setModalOpen(true)}>
                        {t("terms_of_use")} / {t("copyright")}
                    </span>
                </div>
                <TermsModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
                <div>
                    <a className="text-sm c-dark-blue px-3 fw-light" href="https://www.datacm.com/" target="_blank" rel="noreferrer">
                        ©2020-{new Date().getFullYear()} - DCM {t("all_rights_reserved")}
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;