import { useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";

import Header from './core/Header';
import DashboardLayoutPage from './pages/Dashboard/DashboardLayoutPage';
import StyleguideLayoutPage from './pages/Styleguide/StyleguideLayoutPage';
import AuthRoute from "./guards/AuthRoute";
import UnauthorizedPage from "./pages/Secure/UnauthorizedPage";
import LogoutPage from "./pages/Secure/LogoutPage";
import ForbiddenPage from "./pages/Secure/ForbiddenPage";
import NotFoundPage from "./pages/Secure/NotFoundPage";
import ServerErrorPage from "./pages/Secure/ServerErrorPage";
import SessionExpiredPage from "./pages/Secure/SessionExpiredPage";
import i18n from "./core/i18n";

function App() {

  const [showLoading, setShowLoading] = useState(false);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <div className="App">
          <div className="header">
            <Header showLoading={showLoading}></Header>
          </div>
          <div className="main bc-grey-200">
            <Routes>
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/secure" element={<UnauthorizedPage />} />
              <Route path="/forbidden" element={<ForbiddenPage />} />
              <Route path="/server-error" element={<ServerErrorPage />} />
              <Route path="/session-expired" element={<SessionExpiredPage />} />
              <Route path="/" element={<AuthRoute />}>
                <Route path="/styleguide" element={<StyleguideLayoutPage />} />
                <Route path="/" element={<DashboardLayoutPage showLoading={showLoading} setShowLoading={setShowLoading} />} />
              </Route>
              <Route path="*" exact element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
