const Typography = () => {
    return (
        <div className="row">
            <div className="col-12">
                <h1 className="my-4 c-yellow">Typography</h1>
                <h1>Header1</h1>
                <h2>Header2</h2>
                <p className="fw-semi-bold">Body1</p>
                <p>Body2</p>
                <p className="text-sm">Body3</p>
                <p className="text-sm fw-light">Body4</p>
            </div>
        </div>
    );
}

export default Typography;