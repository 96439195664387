import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from "react-i18next";
import Growth from "../atoms/Growth";

const MAX_MOBILE_SCREEN_WIDTH = 576;

const PieChart = ({ data, scaleFactor, headerText, emptyStatePlaceholder, isLoading }) => {

    const { t } = useTranslation();
    const barColors = ['#1A263F', '#1B598E', '#35A2CD', '#8CD0EB', '#C1EDFF', '#F1C85E', '#EBA059', '#D29047', '#B7823D', '#ADB54F'];

    const chartTheme = {
        background: '#FFF'
    };

    const emptyState = {
        colors: ['#F1F1F1'],
        data: [{ id: emptyStatePlaceholder, value: 1 }]
    }

    const calculateScale = (value) => {
        return value > 0 ? Math.round(Math.pow(value ?? 1, 1 / scaleFactor)) : 0;
    }

    const containerRef = useRef();
    const tooltipRef = useRef();
    const [width, setWidth] = useState(window.innerWidth);
    const [tooltip, setTooltip] = useState(null);
    const [input, setInput] = useState(null);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    const handleMouseEnter = (event) => {
        if (!input || !tooltipRef || tooltipRef.current === null) return handleMouseLeave();
        const containerBounds = containerRef.current?.getBoundingClientRect();
        if (!containerBounds) return;
        const tooltipContent = (
            <div
                className="bc-white rounded p-2"
                style={{
                    transition: '0s',
                    transform: '0s',
                    boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)',
                    minWidth: 200,
                    position: 'absolute',
                    pointerEvents: 'none',
                    top: calculateBoundsY(containerBounds, event),
                    left: calculateBoundsX(containerBounds, event),
                }}
            >
                <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                        <div className="dot me-2" style={{ backgroundColor: input.datum.color }}></div>
                        <span className="fw-bold c-black-100">{input.datum.id}</span>
                    </div>
                    <div>
                        <Growth growth={input.datum.data?.trend} />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="c-black-100">
                        {t("current")}
                    </span>
                    <span className="c-grey-800">
                        {calculateScale(input.datum.value)}
                    </span>
                </div>
                {input.datum.data?.trend !== 0 && input.datum.data?.prev !== 0 &&
                    <div className="d-flex justify-content-between">
                        <span className="c-black-100">
                            {t("previous")}
                        </span>
                        <span className="c-grey-800">
                            {calculateScale(input.datum.data?.prev)}
                        </span>
                    </div>
                }
            </div>
        );
        setTooltip(tooltipContent);
    };

    const calculateBoundsX = (containerBounds, event) => {
        if (width > MAX_MOBILE_SCREEN_WIDTH) {
            return containerBounds.left - (containerBounds.left - event.clientX);
        }
        if (event.clientX + 250 > containerBounds.right)
            return containerBounds.right - 250;
        return event.clientX;
    }

    const calculateBoundsY = (containerBounds, event) => {
        if (width > MAX_MOBILE_SCREEN_WIDTH)
            return containerBounds.top - (containerBounds.top - event.clientY - 20);
        return event.pageY;
    }

    const handleMouseLeave = () => {
        setInput(null);
        setTooltip(null);
    }

    return (
        <div className="tile bc-white p-4 rounded-4 mt-4">
            {!isLoading &&
                <>
                    <span className="fw-semi-bold c-black-100">{headerText}</span>
                    <div className="row">
                        <div ref={containerRef}
                            onMouseMove={(event) => handleMouseEnter(event)}
                            onMouseLeave={() => handleMouseLeave()}
                            className="col-12 col-md-6 pie-chart-height">
                            <ResponsivePie
                                data={data?.length ? data : emptyState.data}
                                margin={{ top: 24, right: 7, bottom: 34, left: 7 }}
                                innerRadius={0.65}
                                padAngle={2}
                                theme={chartTheme}
                                cornerRadius={6}
                                colors={data?.length ? barColors : emptyState.colors}
                                sortByValue={true}
                                enableArcLinkLabels={false}
                                enableArcLabels={false}
                                activeOuterRadiusOffset={6}
                                tooltip={(input) => {
                                    setInput(input);
                                    return (
                                        <span ref={tooltipRef}></span>
                                    )
                                }}
                                borderWidth={0}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                            />
                        </div>
                        {tooltip && createPortal(tooltip, document.body)}
                        <div className="col-12 col-md-6">
                            <div className="py-md-4 ps-4" style={{ cursor: 'default' }}>
                                <div className="legend" style={{ overflowY: "auto" }}>
                                    {data?.length ?
                                        <>
                                            {
                                                data?.map((item, index) => (
                                                    <div key={item.id + index} className="d-flex align-items-center pb-2 mb-1">
                                                        <div className="dot mx-2" style={{ backgroundColor: barColors[index] }}></div>
                                                        <div className="text-sm fw-light w-75">{item.id}</div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                        :
                                        <div className="d-flex justify-content-center h-100">
                                            <div className="d-flex align-items-center pb-2 mb-1 w-100">
                                                <div className="dot mx-2" style={{ backgroundColor: emptyState.colors[0] }}></div>
                                                <span className="text-sm fw-light">{emptyState.data[0].id}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {isLoading && <>
                <div className="placeholder-glow mb-4 ms-4">
                    <span className="placeholder c-grey-300 rounded-4 px-5 mb-2 w-25"></span>
                </div>
                <div className="row h-100">
                    <div className="col-6 placeholder-glow d-flex align-items-center justify-content-center mb-5">
                        <div className="placeholder c-grey-300 pie m-0 mb-6"></div>
                    </div>
                    <div className="col-3 mb-4">
                        <div className="row">
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-75 mb-2"></div>
                            </div>
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-75 mb-2"></div>
                            </div>
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 mb-4">
                        <div className="row">
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>
                            <div className="col-6 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </>}
        </div >
    );
}

export default PieChart;