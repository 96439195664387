import Typography from './components/Typography';
import Colors from './components/Colors';

const StyleguideLayoutPage = () => {
    return (

        <div className="container">
            <Typography />
            <Colors/>
        </div>
    );
}

export default StyleguideLayoutPage;