const Growth = ({ growth }) => {

    const getGrowthSinge = (growth) => {
        if (growth > 0) return "+"
        return ""
    }
    
    return (<>
        {(!!growth) && <div
            className={`${growth >= 0 ? 'c-green' : 'c-red'} text-sm d-flex`}>
            {getGrowthSinge(growth)}{growth.toFixed(2)}%
            <img alt={growth >= 0 ? "growth up" : "growth down"}
                src={growth >= 0 ? `${process.env.PUBLIC_URL}/images/icons/arrow-rise.svg`: `${process.env.PUBLIC_URL}/images/icons/arrow-fall.svg`} />
        </div>
        }
    </>
    );
}

export default Growth;