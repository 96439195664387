import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import AuthInterceptor from './interceptors/auth-interceptor';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

AuthInterceptor();

root.render(
  <App />
);
