import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const TermsModal = ({ modalOpen, setModalOpen }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Modal show={modalOpen} onHide={() => setModalOpen(false)} dialogClassName={"primaryModal"}>
                <Modal.Body>
                    <div className="text-end">
                        <div className="cursor-pointer"
                            onClick={() => setModalOpen(false)}>
                            <img alt="close" src={`${process.env.PUBLIC_URL}/images/icons/close.svg`} />
                        </div>
                    </div>
                    <h1 className="ms-2 ms-md-5 py-4">{t("general_terms_of_use")}</h1>
                    <div className="modal-overflow mx-2 mx-md-5 pe-2">
                        <div className="pb-4">
                            <h5>{t("web_solutions")}</h5>

                            <p>
                                &emsp;{t("web_solutions_content_1")}
                            </p>
                            <p>
                                &emsp;{t("web_solutions_content_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("exclusion_warranties")}</h5>

                            <p>
                                &emsp;{t("exclusion_warranties_content_1")}
                            </p>
                            <p>
                                &emsp;{t("exclusion_warranties_content_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("limitation_liability_clause")}</h5>

                            <p>
                                &emsp;{t("limitation_liability_clause_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("indemnity")}</h5>

                            <p>
                                &emsp;{t("indemnity_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("information_provided_electronically")}</h5>

                            <p>
                                &emsp;{t("information_provided_electronically_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("links_and_software")}</h5>

                            <p>
                                &emsp;{t("links_and_software_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("damage_others")}</h5>

                            <p>
                                &emsp;{t("damage_others_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("governing_jurisdiction")}</h5>

                            <p>
                                &emsp;{t("governing_jurisdiction_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("privacy")}</h5>

                            <p>
                                &emsp;{t("privacy_content_1")}
                                &nbsp;
                                <a href="https://www.datacm.com/privacy-policy/" target="_blank" rel="noreferrer">https://www.datacm.com/privacy-policy/</a>
                                {t("privacy_content_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("intellectual_property")}</h5>

                            <p>
                                &emsp;{t("intellectual_property_content_1")}
                            </p>
                            <p>
                                &emsp;{t("intellectual_property_content_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("restrictions_on_use")}</h5>

                            <p>
                                &emsp;{t("restrictions_on_use_content_1")}
                            </p>
                            <p>
                                &emsp;{t("restrictions_on_use_content_2")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("reserve_of_rights")}</h5>

                            <p>
                                &emsp;{t("reserve_of_rights_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("survival_of_obligations")}</h5>

                            <p>
                                &emsp;{t("survival_of_obligations_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("registration")}</h5>

                            <p>
                                &emsp;{t("registration_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("products_and_services")}</h5>

                            <p>
                                &emsp;{t("products_and_services_content")}
                            </p>
                        </div>

                        <div className="pb-4">
                            <h5>{t("orders_products_and_services")}</h5>

                            <p>
                                &emsp;{t("orders_products_and_services_content")}
                            </p>
                        </div>
                    </div>
                    <div className="text-center py-4">
                        <button onClick={() => setModalOpen(false)} type="button" className="btn btn-outline-dark btn-lg rounded-5 px-4">{t("close")}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TermsModal;