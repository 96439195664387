import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ResponsiveLine } from '@nivo/line';

const DESKTOP_CHART_PADDING = 52;
const MOBILE_CHART_PADDING = 43;

const Chart = ({ spentUSD, spentCAD, prevSpentUSD, prevSpentCAD, sideBarOpen, isLoading, title }) => {
    const { t } = useTranslation();
    // Lines color codes                           yellow     cyan       orange     bright-blue 
    const [lineColors, setLineColors] = useState(['#F1C85E', '#0B8ABD', '#EBA059', '#1B598E']);
    const [gridYValues, setGridYValues] = useState([140000, 180000, 220000, 260000]);

    const [data, setData] = useState([]);
    const [min, setMin] = useState(140000);
    const [max, setMax] = useState(260000);
    const [renderChart, setRenderChart] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [chartPadding, setChartPadding] = useState();

    useEffect(() => {
        const dataList = [];
        if (spentUSD && !!spentUSD.length) {
            dataList.push(formDataSet(spentUSD, "USD: " + t("current")))
            if (prevSpentUSD && !!prevSpentUSD.length)
                dataList.push(formDataSet(prevSpentUSD, "USD: " + t("previous")))
        }

        if (spentCAD && !!spentCAD.length) {
            dataList.push(formDataSet(spentCAD, "CAD: " + t("current")))
            if (prevSpentCAD && !!prevSpentCAD.length)
                dataList.push(formDataSet(prevSpentCAD, "CAD: " + t("previous")))
        }

        const combinedArray = [...spentUSD, ...spentCAD, ...prevSpentUSD, ...prevSpentCAD];

        const { smallestInt, largestInt } = combinedArray.reduce(
            (acc, obj) => ({
                smallestInt: Math.min(acc.smallestInt, obj.spent),
                largestInt: Math.max(acc.largestInt, obj.spent),
            }),
            { smallestInt: Number.MAX_SAFE_INTEGER, largestInt: Number.MIN_SAFE_INTEGER }
        );

        if (smallestInt !== Number.MAX_SAFE_INTEGER && largestInt !== Number.MIN_SAFE_INTEGER) {
            setMin(smallestInt);
            setMax(largestInt);
            const interval = Math.round((largestInt - smallestInt) / 3 / 10) * 10;
            const generatedNum1 = Math.round(smallestInt / 10) * 10 + interval;
            const generatedNum2 = Math.round(smallestInt / 10) * 10 + interval * 2;
            setGridYValues([smallestInt, generatedNum1, generatedNum2, largestInt])
        } else {
            setGridYValues([140000, 180000, 220000, 260000]);
            setMin(140000);
            setMax(260000);
        }
        setLinesColors();
        setData(dataList);
        setStartDate(spentCAD[0]?.date ?? spentUSD[0]?.date ?? '');
        setEndDate(spentCAD[spentCAD.length - 1]?.date ?? spentUSD[spentCAD.length - 1]?.date ?? '');
        window.addEventListener('resize', handleScreenWidth);
        handleScreenWidth();
        return () => {
            document.removeEventListener('resize', handleScreenWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spentUSD, spentCAD, prevSpentUSD, prevSpentCAD]);

    useEffect(() => {
        setRenderChart(false);
        setTimeout(rerenderChart, 1);
    }, [sideBarOpen]);

    const rerenderChart = () => {
        setRenderChart(true);
    }

    const formDataSet = (spent, name) => {
        return {
            id: name,
            data: spent.map(m => ({ "x": m.date, "y": m.spent }))
        }
    }

    const chartTheme = {
        background: '#FFF',
        fontSize: '12px',
        fontFamily: 'Source Sans Pro'
    };

    const setLinesColors = () => {
        if (spentCAD && !!spentCAD.length && (!spentUSD || !spentUSD.length))
            setLineColors(['#EBA059', '#1B598E']);
        if ((!spentCAD || !spentCAD.length) && spentUSD && !!spentUSD.length)
            setLineColors(['#F1C85E', '#0B8ABD']);
        if (spentCAD && !!spentCAD.length && spentUSD && !!spentUSD.length)
            setLineColors(['#F1C85E', '#0B8ABD', '#EBA059', '#1B598E']);
    }

    const handleScreenWidth = () => {
        if (window.innerWidth < 576)
            return setChartPadding(MOBILE_CHART_PADDING);
        setChartPadding(DESKTOP_CHART_PADDING);
    }

    return (<>
        <div className="bc-white px-3 pt-3 pb-5 pb-md-4 rounded-4 line-chart-tile" style={{ maxWidth: '100%' }}>
            {!isLoading && <>
                <div className="row g-0">
                    <div className="col-12 col-md-3 text-no-wrap">
                        <div className="fw-semi-bold">{title}</div>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="d-none d-md-block text-end pe-2">
                            {spentUSD && !!spentUSD.length &&
                                <span className="pe-2"><span className="dot-yellow pt-1 me-1"></span>USD:{t("current")}</span>}
                            {spentCAD && !!spentCAD.length &&
                                <span><span className="dot-orange me-1"></span>CAD:{t("current")}</span>}
                            {prevSpentUSD && !!prevSpentUSD.length &&
                                <span><span className="dot-cyan me-1 ms-3"></span>USD:{t("previous")}</span>}
                            {prevSpentCAD && !!prevSpentCAD.length &&
                                <span><span className="dot-blue me-1 ms-3"></span>CAD:{t("previous")}</span>}
                            {(((!prevSpentCAD || prevSpentCAD.length === 0) && spentCAD && !!spentCAD.length)
                                || ((!prevSpentUSD || prevSpentUSD.length === 0) && spentUSD && !!spentUSD.length)) &&
                                <span><span className="dot-grey me-1 ms-3"></span>{t("no_previous_data")}</span>}
                        </div>
                        <div className="row g-0 mt-2 justify-content-md-end d-md-none">
                            {spentUSD && !!spentUSD.length &&
                                <div className="col-6 text-no-wrap pb-2">
                                    <span><span className="dot-yellow pt-1 me-1"></span>USD:{t("current")}</span>
                                </div>}
                            {spentCAD && !!spentCAD.length &&
                                <div className={`col-6 text-no-wrap pb-2 ${!spentUSD || spentUSD.length === 0 ? "ps-4" : ""} `}>
                                    <span><span className="dot-orange me-1"></span>CAD:{t("current")}</span>
                                </div>}
                            {prevSpentUSD && !!prevSpentUSD.length &&
                                <div className="col-6 pb-2 pb-md-0 text-no-wrap">
                                    <span><span className="dot-cyan me-1"></span>USD:{t("previous")}</span>
                                </div>}
                            {prevSpentCAD && !!prevSpentCAD.length &&
                                <div className="col-6 text-no-wrap">
                                    <span><span className="dot-blue me-1"></span>CAD:{t("previous")}</span>
                                </div>}
                            {(((!prevSpentCAD || prevSpentCAD.length === 0) && spentCAD && !!spentCAD.length)
                                || ((!prevSpentUSD || prevSpentUSD.length === 0) && spentUSD && !!spentUSD.length)) &&
                                <div className="col-6 text-no-wrap me-0">
                                    <span><span className="dot-grey me-1 ms-3"></span>{t("no_previous_data")}</span>
                                </div>}
                        </div>
                    </div>
                </div>
                {renderChart && <div style={{height: 280}}>
                    <ResponsiveLine
                        data={data}
                        style={{ maxWidth: '100%' }}
                        margin={{ top: 20, right: chartPadding, bottom: 40, left: chartPadding }}
                        xScale={{ type: 'point' }}
                        colors={lineColors}
                        yScale={{
                            type: 'linear',
                            min: min,
                            max: max,
                            stacked: false,
                            reverse: false
                        }}
                        theme={chartTheme}
                        gridYValues={gridYValues}
                        axisLeft={{
                            format: ".2s",
                            tickSize: 0,
                            tickPadding: 20,
                            tickRotation: 0,
                            legend: '',
                            tickValues: gridYValues,
                            legendOffset: 0,
                            legendPosition: 'middle'
                        }}
                        yFormat=".0s"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 0,
                            tickPadding: 20,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        maxWidth={'100%'}
                        lineWidth={3}
                        enableGridX={false}
                        curve="monotoneX"
                        pointSize={2}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        enableSlices="x"
                        enablePoints={false}
                        useMesh={true}
                        sliceTooltip={({ slice }) => {
                            return (
                                <div className="bc-white rounded-4 box-shadow tooltip-width chart-tooltip">
                                    <div className='row p-3'>
                                        {slice.points.find(p => p.serieId === `CAD: ${t('current')}`) &&
                                            <>
                                                <div className='col-1 mb-1'>
                                                    <span className="dot-orange me-1"></span>
                                                </div>
                                                <div className='col-5 mb-1 text-md'>
                                                    {!!spentUSD && spentUSD.length > 0 && !!spentCAD && spentCAD.length > 0 &&
                                                        <span>CAD:</span>
                                                    }
                                                    {t("current")}
                                                </div>
                                                <div className='col-6 text-end mb-1 text-md'>
                                                    {(Math.round(slice.points.find(p => p.serieId === `CAD: ${t('current')}`)?.data.y * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                </div>
                                            </>
                                        }
                                        {slice.points.find(p => p.serieId === `USD: ${t('current')}`) &&
                                            <>
                                                <div className='col-1 mb-1'>
                                                    <span className="dot-yellow me-1"></span>
                                                </div>
                                                <div className='col-5 mb-1 text-md'>
                                                    {!!spentUSD && spentUSD.length > 0 && !!spentCAD && spentCAD.length > 0 &&
                                                        <span>USD:</span>
                                                    }
                                                    {t("current")}
                                                </div>
                                                <div className='col-6 text-end mb-1 text-md'>
                                                    {(Math.round(slice.points.find(p => p.serieId === `USD: ${t('current')}`)?.data.y * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                </div>
                                            </>
                                        }
                                        <div className='col-1 mb-3'></div>
                                        <div className='col-11 text-sm'>
                                            {spentCAD?.length > 0 &&
                                                <div>
                                                    {spentCAD.find(usd => usd.date === slice.points.find(p => p.serieId === `CAD: ${t('current')}`)?.data.x)?.interval}
                                                </div>}
                                            {spentCAD?.length === 0 && spentUSD?.length > 0 &&
                                                <div>
                                                    {spentUSD.find(usd => usd.date === slice.points.find(p => p.serieId === `USD: ${t('current')}`)?.data.x)?.interval}
                                                </div>}
                                        </div>
                                    </div>
                                    <div className='row px-3 pb-3'>
                                        {slice.points.find(p => p.serieId === `CAD: ${t('previous')}`) &&
                                            <>
                                                <div className='col-1 mb-1'>
                                                    <span className="dot-blue me-1"></span>
                                                </div>
                                                <div className='col-5 mb-1 text-md'>
                                                    {!!spentUSD && spentUSD.length > 0 && !!spentCAD && spentCAD.length > 0 &&
                                                        <span>CAD:</span>
                                                    }
                                                    {t("previous")}
                                                </div>
                                                <div className='col-6 text-end mb-1 text-md'>
                                                    {(Math.round(slice.points.find(p => p.serieId === `CAD: ${t('previous')}`)?.data.y * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                </div>
                                            </>
                                        }
                                        {slice.points.find(p => p.serieId === `USD: ${t('previous')}`) &&
                                            <>
                                                <div className='col-1 mb-1'>
                                                    <span className="dot-cyan me-1"></span>
                                                </div>
                                                <div className='col-5 mb-1 text-md'>
                                                    {!!spentUSD && spentUSD.length > 0 && !!spentCAD && spentCAD.length > 0 &&
                                                        <span>USD:</span>
                                                    }
                                                    {t("previous")}
                                                </div>
                                                <div className='col-6 text-end mb-1 text-md'>
                                                    {(Math.round(slice.points.find(p => p.serieId === `USD: ${t('previous')}`)?.data.y * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                </div>
                                            </>
                                        }
                                        <div className='col-1'></div>
                                        <div className='col-11 text-sm c-dark-blue'>
                                            {prevSpentCAD?.length > 0 &&
                                                <div>
                                                    {prevSpentCAD.find(usd => usd.date === slice.points.find(p => p.serieId === `CAD: ${t('previous')}`)?.data.x)?.interval}
                                                </div>}
                                            {prevSpentCAD?.length === 0 && spentUSD?.length > 0 &&
                                                <div>
                                                    {prevSpentUSD.find(usd => usd.date === slice.points.find(p => p.serieId === `USD: ${t('previous')}`)?.data.x)?.interval}
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    />
                    <div className="row g-0 bc-white chart-overlay d-md-none pb-5">
                        <div className="col-6">{startDate}</div>
                        <div className="col-6 text-end">{endDate}</div>
                    </div>
                </div>}

            </>}
            {isLoading && <>
                <div className="placeholder-glow mb-4">
                    <span className="placeholder c-grey-300 rounded-4 px-5 mb-2"></span>
                </div>
                <div className="row">
                    <div className="col-12 placeholder-glow d-flex mb-4">
                        <div className="placeholder c-grey-300 rounded-4 px-3 mb-2"></div>
                        <div className="w-100 line mx-2"></div>
                    </div>
                    <div className="col-12 placeholder-glow d-flex mb-4">
                        <div className="placeholder c-grey-300 rounded-4 px-3 mb-2"></div>
                        <div className="w-100 line mx-2"></div>
                    </div>
                    <div className="col-12 placeholder-glow d-flex mb-4">
                        <div className="placeholder c-grey-300 rounded-4 px-3 mb-2"></div>
                        <div className="w-100 line mx-2"></div>
                    </div>
                    <div className="col-12 placeholder-glow d-flex mb-4">
                        <div className="placeholder c-grey-300 rounded-4 px-3 mb-2"></div>
                        <div className="w-100 line mx-2"></div>
                    </div>
                    <div className="col-12 placeholder-glow d-flex">
                        <div className="placeholder c-grey-300 rounded-4 px-3 mb-2"></div>
                        <div className="w-100 line mx-2"></div>
                    </div>
                    <div className="col placeholder-glow text-center">
                        <span className="placeholder c-grey-300 rounded-4 w-25"></span>
                    </div>
                    <div className="col placeholder-glow text-center">
                        <span className="placeholder c-grey-300 rounded-4 px-4"></span>
                    </div>
                    <div className="col placeholder-glow text-center">
                        <span className="placeholder c-grey-300 rounded-4 px-4"></span>
                    </div>
                    <div className="col placeholder-glow text-center">
                        <span className="placeholder c-grey-300 rounded-4 px-4"></span>
                    </div>
                    <div className="col placeholder-glow text-center">
                        <span className="placeholder c-grey-300 rounded-4 px-4"></span>
                    </div>
                </div>
            </>}
        </div>
    </>
    );
}

export default Chart;