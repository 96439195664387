import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AuthHandler from "../services/common/auth-handler";

const authHandler = AuthHandler();

const Header = ({ showLoading }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(authHandler.getLanguage());
  }, [i18n])

  useEffect(() => {
    if (showLoading) setAnimation('fade-in');
  }, [showLoading])

  const [animation, setAnimation] = useState('');

  return (
    <Navbar className="p-2" expand="lg">
      <Navbar.Brand href="/">
        <img
          alt="dcm-logo"
          role="button"
          className="px-4"
          style={{ height: 33 }}
          src={`${process.env.PUBLIC_URL}/images/dcm-logo.svg`} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" />
      <Navbar.Collapse id="basic-navbar-nav">
        {
          (!showLoading && authHandler.isAuthenticated()) &&
          <>
            <Nav className="me-auto px-5">
              <Nav.Link className={`ps-md-5 text-center ${animation}`} href="/">{t('basic_dashboard')}</Nav.Link>
            </Nav>
            <Nav className="ms-auto ps-5 pe-4">
              <Nav.Link className={`text-center ${animation}`} as={NavLink} to="/logout">
                <span>{t('logout')}
                  <img
                    className="ps-1"
                    alt="Logout"
                    src={`${process.env.PUBLIC_URL}/images/icons/logout.svg`} />
                </span>
              </Nav.Link>
            </Nav>
          </>
        }

      </Navbar.Collapse>

    </Navbar>);
}

export default Header;