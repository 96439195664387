import Growth from '../atoms/Growth';

const GrowthMetricCard = ({ title, amount1, growth1, metric, metric1, amount2, growth2, metric2, isLoading }) => {

    const getAmount = () => {
        if (!!amount1 && amount1 !== 0)
            return amount1 % 1 === 0 ? amount1.toLocaleString() : (Math.round(amount1 * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
        if (!!amount2 && amount2 !== 0)
            return (Math.round(amount2 * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
        return 0;
    }

    return (
        <div className="bc-white px-3 pt-3 rounded-4 border-0 card h-100 box-shadow" >
            <div className="row">
                <div className="col-8 mb-2 c-grey-800 card-title">{title}</div>
                {((!!amount1 && !amount2) || (!amount1 && !!amount2)) && <>
                    <div className="col-4">
                        <Growth growth={growth1 || growth2} />
                    </div>
                    <h1 className="col-12 c-dark-blue">
                        {metric} {getAmount()}
                    </h1>
                </>
                }
                {!!amount2 && !!amount1 && <>
                    <div className="col-8 d-flex align-items-baseline mb-2 ">
                        <div className="c-dark-blue pt-2">{metric1}</div>
                        <h2 className="c-dark-blue ps-1">{(Math.round(amount1 * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</h2>
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center pb-2">
                        <Growth growth={growth1} />
                    </div>
                    <div className="col-8 d-flex align-items-baseline mb-2">
                        <div className="c-dark-blue pt-2">{metric2}</div>
                        <h2 className="c-dark-blue ps-1">{(Math.round(amount2 * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</h2>
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center pb-2">
                        <Growth growth={growth2} />
                    </div>
                </>
                }
                {!amount2 && !amount1 && <>
                    <div className={`col-4 ${isLoading && 'placeholder-glow'}`}>
                        {isLoading &&
                            <span className="w-100 placeholder c-grey-300 rounded-4"></span>
                        }
                    </div>
                    <h1 className={`col-12 c-dark-blue ${isLoading && 'placeholder-glow'}`}>
                        {isLoading
                            ?
                            <span className="w-25 placeholder c-grey-300 rounded-4"></span>
                            : 0
                        }
                    </h1>
                </>
                }
            </div>
        </div>);
}

export default GrowthMetricCard;