import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const TOOLTIP_MIN_LENGTH = 24;
const CustomersSearchPanel = ({
    customers,
    filteredCustomers,
    customersCount,
    toggle,
    selectedCustomers,
    setSelectedCustomers,
    onSearch,
    isLoading,
    onCloseClick,
    listRef,
    advancedSelection,
    setAdvancedSelection }) => {

    const { t } = useTranslation();

    const [checked, setChecked] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleCheck = (customer) => {
        if (selectAll) {
            setSelectAll(false);
        }

        var updatedList = [...checked];
        if (!checked.some(c => c === customer.id)) {
            updatedList = [...checked, customer.id];
        } else {
            updatedList.splice(checked.indexOf(customer.id), 1);
        }
        setChecked(updatedList);
    };

    const handleSelectAll = () => {
        if (!selectAll)
            setChecked(customers.map(customer => customer.id));
        else
            setChecked([]);

        setSelectAll(!selectAll);
    }

    const onApply = () => {
        setSelectedCustomers(customers?.filter(value => checked?.includes(value.id)));
        closeOnMobile();
    }

    const selectCustomers = (index) => {
        setTooltip('');
        setSelectedCustomers([filteredCustomers[index]]);
        closeOnMobile();
    }

    const closeOnMobile = () => {
        if (window.screen.width <= 576)
            onCloseClick(false);
    }

    useEffect(() => {
        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    useEffect(() => {
        if (advancedSelection === false) {
            setChecked([]);
            setSelectAll(false);
        } else {
            setChecked(selectedCustomers.map(customer => customer.id));
        }
    }, [advancedSelection, customersCount, selectedCustomers])

    const [tooltip, setTooltip] = useState('');
    const [hoveredCustomer, setHoveredCustomer] = useState('');

    const onMouseEnter = (index) => {
        setTooltip(filteredCustomers[index]?.name);
        setHoveredCustomer(index);
    }

    const onMouseLeave = () => {
        setHoveredCustomer('');
    }

    const CustomerRow = ({ index, style }) => (
        <div style={style} onClick={() => selectCustomers(index)} onMouseEnter={() => onMouseEnter(index)} onMouseLeave={() => onMouseLeave()}>
            <div className="pb-2">
                <div className={`m-1 mb-0 rounded panel-item ${selectedCustomers?.length === 1 && filteredCustomers[index]?.code === selectedCustomers[0]?.code ? 'selected' : ''}`}>
                    <div className="p-2" style={{ position: 'relative' }}>
                        {filteredCustomers[index]?.name.length > TOOLTIP_MIN_LENGTH &&
                            <>
                                <div id={`customer-anchor-${index}`}>
                                    <span className="d-block c-dark-blue text-truncate pb-1">
                                        {filteredCustomers[index]?.name}
                                    </span>
                                    <span className="d-block c-grey-800 text-truncate text-sm fw-light pb-1">{filteredCustomers[index]?.code}</span>
                                </div>
                            </>
                        }
                        {filteredCustomers[index]?.name.length <= TOOLTIP_MIN_LENGTH && <>
                            <span
                                className="d-block c-dark-blue text-truncate pb-1">
                                {filteredCustomers[index]?.name}
                            </span>
                            <span className="d-block c-grey-800 text-truncate text-sm fw-light pb-1">{filteredCustomers[index]?.code}</span>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className="height-100 border-top px-2">
            {filteredCustomers && !isLoading &&
                <div className="d-flex h-100 flex-column px-3 px-md-1">
                    <div className="d-flex justify-content-between border-bottom pt-4 pb-3 py-md-3">
                        <span className="c-grey-600 fw-semi-bold">{t("customer")} </span>
                        <img
                            alt="close"
                            role="button"
                            onClick={() => toggle()}
                            className="px-1"
                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                    </div>

                    {customers.length > 1 &&
                        <div className="d-flex justify-content-between border-bottom pt-3 pb-2 pb-md-3 mb-3">
                            <span className="c-dark-blue">{t("advanced_selection")}</span>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    style={{ cursor: 'pointer' }}
                                    name="advanced-selection"
                                    type="checkbox"
                                    checked={advancedSelection}
                                    onChange={() => setAdvancedSelection(!advancedSelection)}
                                    role="switch" />
                            </div>
                        </div>
                    }

                    {advancedSelection && <div className="fw-semi-bold c-dark-blue pb-2">{checked?.length} {t("selected")}</div>}

                    {
                        customersCount > 7 &&
                        <div className={`d-flex ${advancedSelection ? 'pb-1' : 'border-bottom pb-3 mb-2 mb-md-3'}`}>
                            <input
                                type="text"
                                className="dcm-input"
                                value={searchTerm}
                                name="search-input"
                                placeholder={t("search")}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                aria-label="search" />

                            {
                                searchTerm &&
                                <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                    <img
                                        alt="close"
                                        className="px-1"
                                        onClick={() => setSearchTerm('')}
                                        src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                                </button>
                            }
                        </div>
                    }

                    {
                        advancedSelection &&
                        <div className="m-1 mb-3 p-2 border-bottom">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    value={selectAll}
                                    id="select-all-input"
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll} />

                                <label className="form-check-label cursor-pointer" htmlFor="select-all-input">
                                    <span className="fw-semi-bold c-dark-blue px-2">
                                        {!selectAll && <span>{t("select_all")}&nbsp;</span>}
                                        {selectAll && <span>{t("unselect_all")}&nbsp;</span>}
                                        {customersCount}
                                    </span>
                                </label>
                            </div>
                        </div>
                    }

                    {!advancedSelection &&
                        <div style={{ height: '100%' }}>
                            {
                                tooltip &&
                                <Tooltip
                                    className="customers-tooltip"
                                    anchorSelect={`#customer-anchor-${hoveredCustomer}`}
                                    place="right">
                                    {tooltip}
                                </Tooltip>
                            }

                            {
                                filteredCustomers.length > 0 &&
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="customers-list"
                                            height={height}
                                            itemCount={filteredCustomers?.length}
                                            itemSize={64}
                                            width={width}
                                            ref={listRef}
                                        >
                                            {CustomerRow}
                                        </List>
                                    )}
                                </AutoSizer>
                            }
                            {
                                filteredCustomers.length === 0 &&
                                <div className="text-center">
                                    <img
                                        alt="no-results-found"
                                        src={`${process.env.PUBLIC_URL}/images/no-results.svg`} />
                                    <span className="d-block text-sm py-2">{t("no_results")}</span>
                                    <span className="d-block text-sm fw-light px-2">{t("no_results_disclaimer")}</span>
                                </div>
                            }
                        </div>
                    }

                    {advancedSelection &&
                        <>
                            <div className="d-flex flex-column" style={{ overflowX: 'hidden' }}>
                                {
                                    filteredCustomers.length > 0 &&
                                    filteredCustomers?.map((customer) =>
                                        <div
                                            key={customer.id}
                                            className={`row g-0 m-1 mb-0 p-2 rounded panel-item 
                                                ${selectedCustomers.some(c => c.id === customer.id) ? 'selected' : ''}
                                                ${checked.some(c => c === customer.id) ? 'checked' : ' '} `}
                                            onClick={() => handleCheck(customer)}>
                                            <div className="col-1">
                                                <input
                                                    className="form-check-input"
                                                    value={customer.id}
                                                    onChange={() => handleCheck(customer)}
                                                    name={"select-" + customer?.code}
                                                    type="checkbox"
                                                    checked={checked.includes(customer?.id)} />
                                            </div>
                                            <div className="col-11">
                                                <div className="ps-2">
                                                    {customer.name.length > TOOLTIP_MIN_LENGTH && <>
                                                        <span
                                                            data-bs-toggle="tooltip"
                                                            onMouseEnter={onMouseEnter}
                                                            data-bs-placement="right"
                                                            title={`${customer.name}`}
                                                            className="d-block c-dark-blue text-truncate pb-1">
                                                            {customer.name}
                                                        </span>
                                                        <span className="d-block c-grey-800 text-truncate text-sm fw-light pb-1">{customer.code}</span>
                                                    </>
                                                    }
                                                    {customer.name.length <= TOOLTIP_MIN_LENGTH && <>
                                                        <span
                                                            className="d-block c-dark-blue text-truncate pb-1">
                                                            {customer.name}
                                                        </span>
                                                        <span className="d-block c-grey-800 text-truncate text-sm fw-light pb-1">{customer.code}</span>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    filteredCustomers.length === 0 &&
                                    <div className="text-center">
                                        <img
                                            alt="no-results-found"
                                            src={`${process.env.PUBLIC_URL}/images/no-results.svg`} />
                                        <span className="d-block text-sm py-2">{t("no_results")}</span>

                                        <span className="d-block text-sm fw-light px-2">{t("no_results_disclaimer")}</span>
                                    </div>
                                }


                            </div>

                            <div className="py-3 border-top mt-auto">
                                <button
                                    disabled={checked?.length === 0}
                                    className="btn-regular fw-semi-bold py-1"
                                    onClick={onApply}>
                                    {t("apply")}
                                </button>
                            </div>
                        </>
                    }
                </div>
            }
            {isLoading &&
                <div className="row g-0 overflow-hidden">
                    <div className="col-5 placeholder-glow my-4">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-6 my-4"></div>
                    <div className="col-1 placeholder-glow my-4">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 border-bottom"></div>
                    <div className="col-12 placeholder-glow mt-3 mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-2">
                        <div className="placeholder c-grey-300 rounded-4 w-100"></div>
                    </div>
                    <div className="col-12 placeholder-glow mb-4">
                        <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                    </div>
                    <div className="col-8 placeholder-glow">
                        <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                    </div>
                    <div className="col-1 placeholder-glow">
                    </div>
                    <div className="col-3 placeholder-glow">
                        <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default CustomersSearchPanel;