import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DateRangePicker, CustomProvider } from 'rsuite';
import frFR from 'rsuite/locales/fr_FR';
import enGB from 'rsuite/locales/en_GB';

import CustomersService from '../../services/customers-service';
import OrdersService from '../../services/orders-service';
import AuthHandler from "../../services/common/auth-handler";

import GrowthMetricCard from '../../components/molecules/GrowthMetricCard';
import CustomersSearchPanel from '../../components/organisms/CustomersSearchPanel';
import Chart from '../../components/organisms/Chart';
import Footer from "../../core/Footer";
import PieChart from "../../components/organisms/PieChart";
import BarChart from "../../components/organisms/BarChart";
import UsersTable from "../../components/organisms/UsersTable";

const customersService = CustomersService();
const orderService = OrdersService();

const SCALE_FACTOR = 0.5;
const FIRST_REDIRECT_KEY = 'first-redirect';
const USER_LANGUAGE_KEY = 'language';
const FR_LANGUAGE_KEY = 'fr';
const MAX_MOBILE_SCREEN_WIDTH = 576;

const authHandler = AuthHandler();
const DashboardLayoutPage = ({ showLoading, setShowLoading }) => {
    const [language, setLanguage] = useState();
    const listRef = useRef();
    const { t, i18n } = useTranslation();
    const [showPanel, setShowPanel] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customersLoading, setCustomersLoading] = useState(true);

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [advancedSelection, setAdvancedSelection] = useState(false);

    const [showItemCategory, setShowItemCategory] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [totalUSD, setTotalAmountUSD] = useState();
    const [totalCAD, setTotalAmountCAD] = useState();
    const [totalCount, setTotalCount] = useState();
    const [growthCount, setGrowthCount] = useState();
    const [growthUSD, setGrowthUSD] = useState();
    const [growthCAD, setGrowthCAD] = useState();
    const [spentUSD, setSpentUSD] = useState([]);
    const [spentCAD, setSpentCAD] = useState([]);
    const [spentLoading, setSpentLoading] = useState(true);
    const [prevSpentUSD, setPrevSpentUSD] = useState([]);
    const [prevSpentCAD, setPrevSpentCAD] = useState([]);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [endRange, setEndRange] = useState();
    const [startRange, setStartRange] = useState();
    const [filteredCustomers, setFilteredCustomers] = useState(customers);

    const [totalByItemType, setTotalByItemType] = useState([]);
    const [totalByActiveUsers, setTotalByActiveUsers] = useState([]);
    const [totalByItemCategory, setTotalByItemCategory] = useState([]);
    const [totalByUserGroup, setTotalByUserGroup] = useState([]);
    const [totalLoading, setTotalLoading] = useState(true);

    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    const dropdownRef = useRef(null);

    const [options, setOptions] = useState([
        { label: t("yesterday"), value: 1, selected: false },
        { label: t("last_7_days"), value: 6, selected: false },
        { label: t("last_30_days"), value: 30, selected: true },
        { label: t("last_90_days"), value: 90, selected: false },
        { label: t("custom"), value: 100, selected: false }
    ]);

    const customTimeRenege = options[4];
    const [prevFilters, setPrevFilters] = useState({});

    const toogleAdvancedSelection = (selection) => {
        setAdvancedSelection(selection);

        if (!selection) {
            const selectedCustomerIndex = customers.findIndex(customer => customer.id === +authHandler.getCurrentCustomer());
            setSelectedCustomers([customers[selectedCustomerIndex]]);
        }
    }

    const tooglePanel = () => {
        setShowPanel(!showPanel);

        if (!showPanel && selectedCustomers.length === 1) {
            setTimeout(() => {
                const selectedCustomerIndex = customers.findIndex(customer => customer.id === selectedCustomers[0].id);
                listRef?.current?.scrollToItem(selectedCustomerIndex, "center");
            }, 50);
        }
    }

    useEffect(() => {
        if (!advancedSelection) {
            setTimeout(() => {
                const selectedCustomerIndex = customers.findIndex(customer => customer.id === selectedCustomers[0].id);
                listRef?.current?.scrollToItem(selectedCustomerIndex, "center");
            }, 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedSelection])

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[2]);

    const [auth, setAuth] = useState(authHandler.getAuth());
    const [localeKey, setLocaleKey] = React.useState('enGB');

    const [animation, setAnimation] = useState('');

    useEffect(() => {
        const animate = () => {
            setAnimation('fade-out');
            setTimeout(() => { setAnimation(''); setShowLoading(false); }, 1500)
        }

        const redirect = window.localStorage.getItem(FIRST_REDIRECT_KEY);
        if (!redirect) {
            window.localStorage.setItem(FIRST_REDIRECT_KEY, 1);
            setShowLoading(true);
            setTimeout(() => animate(), 1000);
        }
    }, [setShowLoading])

    useEffect(() => {
        const language = authHandler.getLanguage();
        i18n.changeLanguage(language);
        if (language === "en")
            setLocaleKey(enGB);
        else
            setLocaleKey(frFR);

        setOptions([
            { label: t("yesterday"), value: 1, selected: false },
            { label: t("last_7_days"), value: 6, selected: false },
            { label: t("last_30_days"), value: 30, selected: true },
            { label: t("last_90_days"), value: 90, selected: false },
            { label: t("custom"), value: 100, selected: false }
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n])

    useEffect(() => {
        setSelectedOption(options[2]);
    }, [options])

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const onSearch = (value) => {
        const filtered = customers.filter(function (customer) {
            return customer?.name?.toLowerCase().includes(value.toLowerCase()) || customer?.code?.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredCustomers([...filtered]);
    }

    const setYesterdayDate = () => {
        const today = new Date();
        setMaxDate(today);
    }

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const cleanPrevValues = () => {
        setTotalAmountUSD(null);
        setTotalAmountCAD(null);
        setGrowthUSD(null);
        setGrowthCAD(null);
        setTotalByUserGroup([]);
    }

    useEffect(() => {
        i18n.changeLanguage(authHandler.getLanguage());
        setLanguage(authHandler.getLanguage());
    }, [i18n, auth])

    useEffect(() => {
        const currentCustomerId = +authHandler.getCurrentCustomer();

        if (selectedCustomers.length === 1 && selectedCustomers[0]?.id === currentCustomerId) {
            setTimeout(() => {
                const selectedCustomerIndex = customers.findIndex(customer => customer.id === currentCustomerId);
                listRef?.current?.scrollToItem(selectedCustomerIndex, "center");
            }, 50);
        }
    }, [customers, selectedCustomers])

    useEffect(() => {
        customersService.init();
        orderService.init();
        const unsubscribe = authHandler.subscribe(auth => setAuth(auth));

        setYesterdayDate();
        document.addEventListener('click', handleOutsideClick);
        window.addEventListener('resize', handleWindowSizeChange);

        customersService
            .get()
            .then((response) => {
                if (response?.data) {
                    setCustomers(response?.data);
                    setFilteredCustomers(response?.data);

                    if (response.data.length > 0) {
                        const selectedCustomerIndex = response?.data.findIndex(customer => customer.id === +authHandler.getCurrentCustomer());
                        setSelectedCustomers([response?.data[selectedCustomerIndex]]);
                    }
                }

                if (response?.response?.status === 422)
                    setShowError(true);
            }).finally(() => setCustomersLoading(false));

        return () => {
            customersService.dispose();
            orderService.dispose();
            document.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('resize', handleWindowSizeChange);
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!selectedOption
            || !selectedCustomers
            || selectedCustomers.length === 0
            || (selectedOption.value === customTimeRenege.value && !startRange))
            return;

        let startDay = new Date();
        let endDay = new Date();
        if (selectedOption.value !== customTimeRenege.value) {
            startDay.setDate(startDay.getDate() - (selectedOption.value + 1));
            endDay.setDate(endDay.getDate() - 1);
            setStartRange(null);
            setEndRange(null);
        } else {
            startDay = startRange;
            endDay = endRange;
        }

        setShowWarning(Math.round((endDay - startDay) / (1000 * 60 * 60 * 24)) >= 365);
        let filters = {
            startDate: startDay.toISOString().split('T')[0],
            endDate: endDay.toISOString().split('T')[0],
            selectedCustomerIds: selectedCustomers.length ? selectedCustomers?.map(a => a?.id) : []
        };

        if (JSON.stringify(prevFilters) === JSON.stringify(filters))
            return;
        setPrevFilters(filters);
        cleanPrevValues();
        orderService.getStartDate(filters)
            .then((response) => {
                if (response.data)
                    setMinDate(new Date(response.data));
            });

        setTotalCount(null);
        setGrowthCount(null);
        setSpentLoading(true);
        orderService.getAmount(filters)
            .then((response) => {
                if (response?.data) {
                    setSpentUSD(response.data?.spentUSD);
                    setSpentCAD(response.data?.spentCAD);
                    setPrevSpentUSD(response.data?.prevSpentUSD);
                    setPrevSpentCAD(response.data?.prevSpentCAD);
                    setTotalAmountUSD(response.data?.totalSpent?.totalUSD);
                    setTotalAmountCAD(response.data?.totalSpent?.totalCAD);
                    setGrowthUSD(response.data?.totalSpent?.growthUSD);
                    setGrowthCAD(response.data?.totalSpent?.growthCAD);
                    setTotalCount(response.data?.totalCount?.total);
                    setGrowthCount(response.data?.totalCount?.growth);
                }
            }).finally(() => setSpentLoading(false));

        setTotalLoading(true);
        Promise.all([
            orderService.getTotalByUserGroup(filters),
            orderService.getTotalByItemType(filters),
            orderService.getTotalByActiveUsers(filters),
            orderService.getTotalByItemCategory(filters)
        ])
            .then(([totalByUserGroup, totalByItemType, totalByActiveUsers, totalByItemCategory]) => {
                if (totalByUserGroup?.data) {
                    setTotalByUserGroup(totalByUserGroup.data.map(
                        (ordersCount) => (
                            {
                                value: Math.pow(ordersCount.total, SCALE_FACTOR),
                                total: ordersCount.total,
                                prevTotal: ordersCount.prevTotal,
                                prev: Math.pow(ordersCount.prevTotal, SCALE_FACTOR),
                                trend: ordersCount.trend,
                                id: language === 'en' ? ordersCount.nameEn : ordersCount.nameFr
                            })));
                }
                if (totalByItemType?.data) {
                    setTotalByItemType(totalByItemType.data.map(
                        (ordersCount) => (
                            {
                                value: Math.pow(ordersCount.total, SCALE_FACTOR),
                                prev: Math.pow(ordersCount.prevTotal, SCALE_FACTOR),
                                total: ordersCount.total,
                                prevTotal: ordersCount.prevTotal,
                                trend: ordersCount.trend,
                                id: language === 'en' ? ordersCount.nameEn : ordersCount.nameFr
                            })));
                }
                if (totalByActiveUsers?.data)
                    setTotalByActiveUsers(totalByActiveUsers?.data);
                if (totalByItemCategory?.data) {
                    setTotalByItemCategory(totalByItemCategory.data.map(
                        (ordersCount) => (
                            {
                                value: Math.pow(ordersCount.total, SCALE_FACTOR),
                                prev: Math.pow(ordersCount.prevTotal, SCALE_FACTOR),
                                total: ordersCount.total,
                                prevTotal: ordersCount.prevTotal,
                                trend: ordersCount.trend,
                                id: language === 'en' ? ordersCount.nameEn : ordersCount.nameFr
                            })));
                    setShowItemCategory(!(totalByItemCategory.data.length === 1 && totalByItemCategory.data[0].nameEn === 'Unspecified'));
                }
            })
            .finally(() => setTotalLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomers, selectedOption, endRange]);

    const adjustDate = (date) => {
        const adjustedDate = new Date(date);
        if(width > MAX_MOBILE_SCREEN_WIDTH) {
            adjustedDate.setDate(1);
            adjustedDate.setMonth(adjustedDate.getMonth() - 1);
        }

        return adjustedDate;
    };

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    return (
        <>
            {showLoading &&
                <div className={`height-100 w-100 bc-grey-200 loader ${animation}`}>
                    <div className="h-100 d-flex align-items-center justify-content-center ">
                        <img
                            alt="dcm-logo"
                            role="button"
                            style={{ height: 200 }}
                            src={`${process.env.PUBLIC_URL}/images/dcm-logo-animated.svg`} />
                    </div>
                </div>
            }

            <div className="d-flex align-items-start">
                {
                    <div className={`overlay bc-white ${showPanel ? '' : 'd-none'}`}>
                        <CustomersSearchPanel
                            customers={customers}
                            filteredCustomers={filteredCustomers}
                            customersCount={customers?.length}
                            toggle={() => tooglePanel()}
                            selectedCustomers={selectedCustomers}
                            onSearch={onSearch}
                            setSelectedCustomers={setSelectedCustomers}
                            isLoading={customersLoading}
                            onCloseClick={setShowPanel}
                            listRef={listRef}
                            advancedSelection={advancedSelection}
                            setAdvancedSelection={toogleAdvancedSelection} />
                    </div>
                }

                <div className="w-100 height-md-100 d-flex flex-column" style={{ overflowY: "auto" }}>
                    <div className="container-fluid bc-grey-200 flex-grow-1 mb-4">
                        <div className="row">
                            {showError &&
                                <div className="bc-bright-red p-3 d-flex justify-content-between" onClick={() => setShowError(false)}>
                                    <div className="c-dark-red">
                                        {t("error_massage")}
                                    </div>
                                    <div className="cursor-pointer"
                                        onClick={() => setShowError(false)}>
                                        <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                                    </div>
                                </div>
                            }
                            {showWarning &&
                                <div className="col-12 p-3 bc-light-yellow">
                                    <div className="row">
                                        <div className="col-11">
                                            {t("incorrect_timerange")}
                                        </div>
                                        <div className="col-1 cursor-pointer text-center"
                                            onClick={() => setShowWarning(false)}>
                                            <img alt="selected" className="pe-2" src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-6 pt-3">
                                <button className="ms-1 ms-md-3 bc-grey-200" onClick={() => tooglePanel()}>
                                    <img alt="menu" src={`${process.env.PUBLIC_URL}/images/icons/menu.svg`} />
                                    <span className="d-none c-dark-blue fw-semi-bold d-md-inline ps-1">{t("filter_by_customer")}</span>
                                </button>
                            </div>
                            <div className="col-6 pt-2 pe-2">
                                <div className="d-flex align-items-center justify-content-end mb-4 me-2 me-md-3">
                                    <div className={`info-tooltip ${window.localStorage.getItem(USER_LANGUAGE_KEY) === FR_LANGUAGE_KEY ? 'd-none d-md-flex' : ''}`}>
                                        <img alt="menu" src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                        <div className={`tooltip-text px-2 ${selectedOption.value === customTimeRenege.value ? 'tooltip-text-margin' : ''}`}
                                        >
                                            {t("data_availability")}
                                        </div>
                                    </div>
                                    <div className="py-1">
                                        <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
                                            <div className="dropdown-toggle fw-semi-bold bc-grey-200 py-1 ps-2" onClick={toggleDropdown}>
                                                {selectedOption.label}
                                                <img
                                                    className="ps-1"
                                                    alt="Arrow down"
                                                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-line-down.svg`} />
                                            </div>
                                            <ul className="dropdown-menu px-1 py-1">
                                                {options.map((option) => (
                                                    <li
                                                        key={option.value}
                                                        className={`my-2 me-4 dropdown-item ${selectedOption.value === option.value ? 'active' : ''}`}
                                                        onClick={() => selectOption(option)}
                                                    >
                                                        <div className="row">
                                                            <div className="col-9">
                                                                {option.value === 100 &&
                                                                    <img className="pe-1" alt="calendar" src={`${process.env.PUBLIC_URL}/images/icons/calendar.svg`} />
                                                                }
                                                                {option.label}
                                                            </div>
                                                            <div className="col-3">
                                                                {selectedOption.value === option.value &&
                                                                    <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    {selectedOption.value === customTimeRenege.value &&
                                        <CustomProvider locale={localeKey}>
                                            <DateRangePicker showOneCalendar={width <= MAX_MOBILE_SCREEN_WIDTH}
                                                defaultCalendarValue={[adjustDate(maxDate), maxDate]}
                                                placement="bottomEnd"
                                                ranges={[{
                                                    label: t("availability_massage"),
                                                    value: [new Date(), new Date()]
                                                }]}
                                                shouldDisableDate={(dateParam) => { return dateParam <= minDate || dateParam >= maxDate; }}
                                                onOk={value => { setStartRange(value[0]); setEndRange(value[1]) }}
                                                placeholder={t("start_date_end_date")}
                                                format={t("date_format")}
                                            />
                                        </CustomProvider>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row px-md-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                                        <GrowthMetricCard
                                            title={t("total_dollars")}
                                            amount1={totalUSD}
                                            growth1={growthUSD}
                                            metric={"$"}
                                            metric1={"USD"}
                                            amount2={totalCAD}
                                            growth2={growthCAD}
                                            metric2={"CAD"}
                                            isLoading={spentLoading}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                                        <GrowthMetricCard
                                            title={t("total_orders")}
                                            amount1={totalCount}
                                            growth1={growthCount}
                                            metric1={""}
                                            isLoading={spentLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Chart
                                    spentUSD={spentUSD}
                                    spentCAD={spentCAD}
                                    prevSpentUSD={prevSpentUSD}
                                    prevSpentCAD={prevSpentCAD}
                                    sideBarOpen={showPanel}
                                    isLoading={spentLoading}
                                    title={t("dollars_spent")}
                                />
                            </div>

                            {
                                totalByItemType.length >= 10 ?
                                    <>
                                        <div className="col-md-6 col-12">
                                            <BarChart
                                                headerText={t("total_item_type")}
                                                data={totalByItemType}
                                                scaleFactor={SCALE_FACTOR} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-md-6 col-12">
                                            <PieChart
                                                headerText={t("total_item_type")}
                                                data={totalByItemType}
                                                scaleFactor={SCALE_FACTOR}
                                                emptyStatePlaceholder={t("no_orders")}
                                                isLoading={totalLoading} />
                                        </div>
                                    </>
                            }

                            {
                                totalByUserGroup.length >= 10 ?
                                    <>
                                        <div className="col-md-6 col-12">
                                            <BarChart
                                                headerText={t("orders_user_groups")}
                                                data={totalByUserGroup}
                                                scaleFactor={SCALE_FACTOR} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-md-6 col-12">
                                            <PieChart
                                                headerText={t("orders_user_groups")}
                                                data={totalByUserGroup}
                                                scaleFactor={SCALE_FACTOR}
                                                emptyStatePlaceholder={t("no_user_groups")}
                                                isLoading={totalLoading} />
                                        </div>
                                    </>
                            }

                            <div className="col-md-6 col-12">
                                <UsersTable
                                    data={totalByActiveUsers}
                                    isLoading={totalLoading} />
                            </div>

                            {showItemCategory && (
                                totalByItemCategory.length >= 10 ?
                                    <>
                                        <div className="col-md-6 col-12">
                                            <BarChart
                                                headerText={t("total_item_category")}
                                                data={totalByItemCategory}
                                                scaleFactor={SCALE_FACTOR} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-md-6 col-12">
                                            <PieChart
                                                headerText={t("total_item_category")}
                                                data={totalByItemCategory}
                                                scaleFactor={SCALE_FACTOR}
                                                emptyStatePlaceholder={t("no_categories")}
                                                isLoading={totalLoading} />
                                        </div>
                                    </>
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default DashboardLayoutPage;