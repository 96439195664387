import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

import { ResponsiveBarCanvas } from '@nivo/bar';
import { useTranslation } from "react-i18next";
import Growth from "../atoms/Growth";

const MAX_MOBILE_SCREEN_WIDTH = 576;

const BarChart = ({ data, scaleFactor, headerText }) => {

    const { t } = useTranslation();

    const barColors = ['#F1C85E', '#1B598E'];

    const containerRef = useRef();
    const tooltipRef = useRef();

    const [width, setWidth] = useState(window.innerWidth);
    const [tooltip, setTooltip] = useState(null);
    const [input, setInput] = useState(null);

    const [ticks, setTicks] = useState([]);
    const [prevDataExist, setPrevDataExist] = useState(false);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        let ticksSet = new Set(data?.map(d => d.total));
        data?.forEach(item => ticksSet.add(item.prevTotal));

        let ticksArray = Array.from(ticksSet);
        ticksArray.sort((x, y) => x - y);

        const minVal = Math.min(...ticksArray);
        const maxVal = Math.max(...ticksArray);

        let interval = linearInterpolation(minVal, maxVal, 6);
        setTicks(interval);

        data.forEach(item => {
            if (item.prev !== 0) {
                setPrevDataExist(true)
                return;
            }
        });
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const linearInterpolation = (start, end, numSteps) => {
        const result = [0];
        for (let i = 1; i < numSteps; i++) {
            const value = (start + Math.round(Math.pow((i / 5), 2) * (end - start)));

            end > 100 ? result.push(roundLastDigit(value)) : result.push(value);
        }
        return result;
    }

    const roundLastDigit = (number) => {
        const lastDigit = number % 10;
        const roundedNumber = number - lastDigit + 10;
        return roundedNumber;
    }

    const chartTheme = {
        background: '#FFF',
        fontFamily: 'Source Sans Pro',
        "axis": {
            "ticks": {
                "text": {
                    "fontSize": 12,
                    "fill": "#575757"
                }
            }
        },
        tooltip: { container: { position: "fixed" } }
    };

    const calculateScale = (value) => {
        return value > 0 ? Math.round(Math.pow(value ?? 1, 1 / scaleFactor)) : 0;
    }

    const handleMouseEnter = (event) => {
        if (!input || !tooltipRef || tooltipRef.current === null) return handleMouseLeave();
        const containerBounds = containerRef.current?.getBoundingClientRect();
        if (!containerBounds) return;
        const tooltipContent = (
            <div
                className="bc-white rounded p-2"
                style={{
                    transition: '0s',
                    transform: '0s',
                    boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)',
                    minWidth: 250,
                    position: 'absolute',
                    pointerEvents: 'none',
                    top: calculateBoundsY(containerBounds, event),
                    left: calculateBoundsX(containerBounds, event),
                }}
            >
                <div className="d-flex align-items-center mb-2">
                    <span className="fw-bold c-black-100">{input?.indexValue}</span>
                    <div className="ps-2">
                        <Growth growth={input.data?.trend} />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="dot me-2" style={{ backgroundColor: barColors[0] }}></div>
                        <span className="c-black-100">
                            {t("current")}
                        </span>
                    </div>

                    <span className="c-grey-800 ps-2">
                        {calculateScale(input.data?.value)}
                    </span>
                </div>
                {prevDataExist &&
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="dot me-2" style={{ backgroundColor: barColors[1] }}></div>
                            <span className="c-black-100">
                                {t("previous")}
                            </span>
                        </div>

                        <span className="c-grey-800 ps-2">
                            {calculateScale(input.data?.prev)}
                        </span>
                    </div>
                }
            </div>
        );
        setTooltip(tooltipContent);
    };

    const calculateBoundsX = (containerBounds, event) => {
        if (width > MAX_MOBILE_SCREEN_WIDTH) {
            let left = containerBounds.left - (containerBounds.left - event.clientX);
            if (containerBounds.right - 200 < left)
                return left - (event.clientX + 200 - containerBounds.right);
            return left;
        }
        if (event.clientX + 250 > containerBounds.right)
            return containerBounds.right - 250;
        return event.clientX;
    }

    const calculateBoundsY = (containerBounds, event) => {
        if (width > MAX_MOBILE_SCREEN_WIDTH)
            return containerBounds.top - (containerBounds.top - event.clientY - 20);
        return event.pageY;
    }

    const handleMouseLeave = () => {
        setInput(null);
        setTooltip(null);
    }

    return (
        <div className="bc-white p-4 rounded-4 mt-4" style={{ height: 335 }}>
            <div className="d-flex justify-content-between align-items-center">
                <span className="fw-semi-bold c-black-100 text-truncate">{headerText}</span>
                <div className="d-flex align-items-center">
                    <div className="dot mx-2" style={{ backgroundColor: barColors[0] }}></div>
                    <span className="c-black-100 text-sm fw-light">{t("current")}</span>
                    {prevDataExist && <>
                        <div className="dot ms-3 me-2" style={{ backgroundColor: barColors[1] }}></div>
                        <span className="c-black-100 text-sm fw-light">{t("previous")}</span>
                    </>}
                    {!prevDataExist && <>
                        <div className="dot-grey ms-3 me-2"></div>
                        <span className="c-black-100 text-sm fw-light">{t("no_previous_data")}</span>
                    </>}
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <div ref={containerRef}
                    onMouseMove={(event) => handleMouseEnter(event)}
                    onMouseLeave={() => handleMouseLeave()}
                    style={{ overflowY: "auto", overflowX: "hidden", height: '240px' }}>
                    <ResponsiveBarCanvas
                        data={data?.sort((a, b) => a.value - b.value)}
                        keys={prevDataExist ? ['value', 'prev'] : ['value']}
                        indexBy="id"
                        margin={{ top: 12, right: 20, bottom: 12, left: 86 }}
                        padding={prevDataExist ? 0.4 : 0.8}
                        valueScale={{
                            type: 'linear',
                            nice: true,
                            clamp: true
                        }}
                        animate={false}
                        innerPadding={5}
                        indexScale={{ type: 'band', round: false }}
                        reverse={false}
                        tooltip={(input) => {
                            setInput(input);
                            return (
                                <span ref={tooltipRef}></span>
                            )
                        }}
                        theme={chartTheme}
                        height={data?.length * 40}
                        groupMode="grouped"
                        enableLabel={false}
                        enableGridY={false}
                        layout="horizontal"
                        borderRadius={4}
                        borderWidth={0}
                        colors={barColors}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={{
                            tickSize: 0,
                            format: (value) => value?.length > 12 ? value.slice(0, 10) + '...' : value,
                            tickPadding: 8,
                            tickRotation: 0,
                            legend: '',
                        }}
                    />
                </div >
                {tooltip && createPortal(tooltip, document.body)
                }
            </div>

            <div className="d-flex justify-content-between pe-2 me-3 fw-light text-sm c-grey-800" style={{ marginLeft: '78px' }}>
                {
                    ticks?.map(tick => (
                        <div className="" key={`tick-${tick}`}>{tick}</div>
                    ))
                }
            </div>
        </div>
    );

}

export default BarChart;