import axios from "axios";
import jwtDecode from "jwt-decode";

const AuthInterceptor = () => {
    const AUTH_ROUTE_PATH = '/secure';
    const SERVER_ERROR_ROUTE_PATH = '/server-error';
    const SESSION_EXPIRED_ROUTE_PATH = '/session-expired';
    const FORBIDDEN_ROUTE_PATH = '/forbidden';
    const AUTH_CHANGE_EVENT = 'auth';
    const ACCESS_TOKEN_KEY = 'access-token';
    const REFRESH_TOKEN_KEY = 'refresh-token';

    const refreshToken = () => {
        let token = window.localStorage.getItem(REFRESH_TOKEN_KEY);

        var url = process.env.REACT_APP_SECURE_API + 'auth/refresh-token';

        return axios.post(url, { 'refreshToken': token });
    };

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.interceptors.request.use(
        request => {
            let token = window.localStorage.getItem(ACCESS_TOKEN_KEY);

            if (!token)
                return Promise.reject();

            const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
            const isDefaultRelativeApiUrl = request.url.startsWith('/');
            if (token && (isApiUrl || isDefaultRelativeApiUrl))
                request.headers.Authorization = "Bearer " + token;
            return request;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        async error => {
            if (!error || !error.response || !error.response.status || !error.response.request)
                return Promise.reject(error);
            const isApiUrl = error.response.request.responseURL?.startsWith(process.env.REACT_APP_API_URL) ?? false;
            const isUnauthorized = error.response.status === 401;
            const isForbidden = error.response.status === 403;

            if (isUnauthorized && isApiUrl) {
                let access_token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
                let refresh_token = window.localStorage.getItem(REFRESH_TOKEN_KEY);

                if (!access_token || !refresh_token)
                    if (window.location.pathname !== AUTH_ROUTE_PATH) {
                        window.location.href = AUTH_ROUTE_PATH;
                        return Promise.reject(error);
                    }

                if (jwtDecode(access_token).exp < Date.now() / 1000) {
                    refreshToken()
                        .then((response) => {
                            if (response?.data?.accessToken) access_token = response?.data?.accessToken;
                            if (response?.data?.refreshToken) refresh_token = response?.data?.refreshToken;

                            window.localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
                            window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);

                            window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));

                            window.location.href = window.location.pathname;
                            Promise.reject(error);
                        }).catch((e) => {
                            window.location.href = SESSION_EXPIRED_ROUTE_PATH;
                            Promise.reject(error);
                        });
                }
            }

            if (isApiUrl && error.response.status >= 500) window.location.href = SERVER_ERROR_ROUTE_PATH;
            if (isApiUrl && isForbidden) window.location.href = FORBIDDEN_ROUTE_PATH;
            return Promise.reject(error);
        });
}

export default AuthInterceptor;