import axios from "axios";

function OrdersService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getAmount = ({ startDate, endDate, selectedCustomerIds }) => {
        return axios
            .post('/orders/amount', { startDate, endDate, selectedCustomerIds }, config())
            .catch(error => error);
    }

    const getStartDate = ({ startDate, endDate, selectedCustomerIds }) => {
        return axios
            .post('/orders/start-date', { startDate, endDate, selectedCustomerIds }, config())
            .catch(error => error);
    }

    const getTotalByItemCategory = ({ startDate, endDate, selectedCustomerIds }) => {
        return axios.post('/orders/item-category', { startDate, endDate, selectedCustomerIds }, config());
    }

    const getTotalByUserGroup = ({ startDate, endDate, selectedCustomerIds }) => {
        return axios.post('/orders/user-groups', { startDate, endDate, selectedCustomerIds }, config());
    }

    const getTotalByItemType = ({ startDate, endDate, selectedCustomerIds }) => {
        return axios.post('/orders/item-type', { startDate, endDate, selectedCustomerIds }, config());
    }

    const getTotalByActiveUsers = ({ startDate, endDate, selectedCustomerIds }) => {
        return axios.post('/orders/active-users', { startDate, endDate, selectedCustomerIds }, config());
    }

    return {
        init,
        dispose,
        getAmount,
        getStartDate,
        getTotalByItemCategory,
        getTotalByUserGroup,
        getTotalByItemType,
        getTotalByActiveUsers
    };
}

export default OrdersService;