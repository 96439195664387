import { Navigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AuthHandler from "../services/common/auth-handler";

const authHandler = AuthHandler();

const AuthRoute = () => {
    const location = useLocation();

    authHandler.retrieve();

    return (
        authHandler.isAuthenticated()
            ? <Outlet />
            : <Navigate
                to={{
                    pathname: '/secure',
                    state: { redirectUrl: location.pathname }
                }}
            />
    )
};

export default AuthRoute;