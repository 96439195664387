import jwt from 'jwt-decode';

const AuthHandler = () => {
    const AUTH_CHANGE_EVENT = 'auth';

    const ACCESS_TOKEN_KEY = 'access-token';
    const REFRESH_TOKEN_KEY = 'refresh-token';
    const USER_LANGUAGE_KEY = 'language';
    const AUTH_ROUTE_PATH = '/secure';

    const getSavedAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);

    const reset = () => {
        window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
    }

    const retrieve = () => {
        let token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        let refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);

        let params = (new URL(document.location)).searchParams;
        let accessTokenParam = params.get("accessToken");
        let refreshTokenParam = params.get("refreshToken");

        if ((!token || !refreshToken) || (accessTokenParam && refreshTokenParam)) {
            if (refreshTokenParam) window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshTokenParam);
            if (accessTokenParam) {
                window.localStorage.setItem(ACCESS_TOKEN_KEY, accessTokenParam);
                window.localStorage.setItem(USER_LANGUAGE_KEY, jwt(accessTokenParam)?.language);
                window.history.pushState({}, document.title, window.location.pathname);
            } else {
                if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
                return;
            }
        }
    }

    const isAuthenticated = () => !!getSavedAccessToken() && !!jwt(getSavedAccessToken());

    const getAuth = () => ({
        isAuthenticated: isAuthenticated()
    });

    const getCurrentCustomer = () => {
        var token = getSavedAccessToken();
        var decodedToken = jwt(token);
        return decodedToken?.currentcustomerid;
    }

    const getLanguage = () => {
        var token = getSavedAccessToken();
        if (token) {
            var decodedToken = jwt(token);
            return decodedToken?.language || 'en';
        }

        const userLanguage = window.localStorage.getItem(USER_LANGUAGE_KEY);
        return userLanguage || 'en';
    }

    const subscribe = (listener, silent = true) => {
        if (!listener) return () => { };
        const callback = () => listener(getAuth());
        window.addEventListener(AUTH_CHANGE_EVENT, callback);
        if (!silent) callback();
        return () => window.removeEventListener(AUTH_CHANGE_EVENT, callback);
    }

    return {
        isAuthenticated,
        reset,
        retrieve,
        getAuth,
        subscribe,
        getLanguage,
        getCurrentCustomer
    };
}

export default AuthHandler;