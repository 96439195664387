import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthHandler from "../../services/common/auth-handler";

const authHandler = AuthHandler();
const ServerErrorPage = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(authHandler.getLanguage());
    }, [i18n])

    return (
        <div className="height-100 d-flex flex-column justify-content-center align-items-center">
            <img
                alt="server error occurred"
                src={`${process.env.PUBLIC_URL}/images/internal-error.svg`} />
            <h1 className="c-dark-blue fw-semi-bold">{t("something_went_wrong")}</h1>
            <div className="text-center w-75">
                <span className="c-dark-blue">
                    {t("server_error_content")}
                </span>
            </div>
        </div>);
}

export default ServerErrorPage;