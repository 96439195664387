import { useState, useRef, useEffect } from "react";
import Growth from "../atoms/Growth";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { createPortal } from "react-dom";

const TABLE_CAPACITY = 6;
const USER_LANGUAGE_KEY = 'language';
const FR_LANGUAGE_KEY = 'fr';

const UsersTable = ({ data, isLoading }) => {
    const [tooltip, setTooltip] = useState(null);
    const { t } = useTranslation();
    const containerRef = useRef();

    const formatCurrency = (usd, cad) => {
        if (!usd && !cad)
            return 0;

        if (usd > 0 && cad > 0)
            return 'USD ' + usd + ', CAD ' + cad;

        return usd || cad;
    }

    const handleMouseEnter = (event, index) => {
        if (data?.current[index] === null || containerRef === null || containerRef.current == null)
            return setTooltip(null);

        const currentUser = data?.current[index];
        const tooltipContent = (
            <div className="bc-white rounded p-2  d-none d-md-block"
                style={{
                    transition: '0s',
                    transform: '0s',
                    minWidth: 250,
                    position: 'absolute',
                    boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)',
                    pointerEvents: 'none',
                    top: event.clientY - (isPrevExist(currentUser) ? 150 : 120),
                    left: event.clientX - 120,
                }}>
                <div className="p-1">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex w-100 align-items-center justify-content-between">
                            <span className="fw-bold c-black-100">{currentUser?.firstName}&nbsp;{currentUser?.lastName}</span>
                            <Growth growth={currentUser?.trend} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex w-100 align-items-center justify-content-between">
                            <span className="c-grey-800">{currentUser?.userName}</span>
                        </div>
                    </div>
                    <div className="row g-0 mb-2 c-black-100">
                        <span className="col-5 text-start">{t("current")}</span>
                        <span className="col-3 text-start">{formatCurrency(currentUser?.totalSumUsd, currentUser?.totalSumCad)}</span>
                        <span className="col-4 text-end">{currentUser?.ordersCount}</span>
                    </div>

                    {isPrevExist(currentUser) &&
                        <div className="row g-0 mb-2 c-black-100">
                            <span className="col-5 text-start">{t("previous")}</span>
                            <span className="col-3 text-start">
                                {formatCurrency(data?.previous?.find(item => item?.userId === currentUser?.userId)?.totalSumUsd, data?.previous?.find(item => item?.userId === currentUser?.userId)?.totalSumCad)}
                            </span>
                            <span className="col-4 text-end">{data?.previous?.find(item => item?.userId === currentUser?.userId)?.ordersCount || 0}</span>
                        </div>
                    }
                </div>
            </div>
        );
        setTooltip(tooltipContent);
    };

    const UserRow = ({ index, style }) => (
        <div
            style={style}
            className={`row fw-light c-black-100 table-row g-0 me-4`}
            onMouseMove={(e) => handleMouseEnter(e, index)}>
            <div className="col-7 d-block d-lg-none align-items-center">
                <span className="d-block text-truncate">{data?.current[index]?.userName}</span>
                <span className="d-block text-truncate">{data?.current[index]?.firstName} {data?.current[index]?.lastName}</span>
            </div>
            <div className="col-3 col-xl-4 d-none d-lg-flex align-items-center">
                <span className="d-block text-truncate">{data?.current[index]?.userName}</span>
            </div>
            <div style={{ height: "36px" }} className="col-4 d-none d-lg-flex align-items-center overflow-hidden">
                {data?.current[index]?.firstName} {data?.current[index]?.lastName}
            </div>
            <div className="col-3 col-xl-2 d-flex align-items-center">
                {formatCurrency(data?.current[index]?.totalSumUsd, data?.current[index]?.totalSumCad)}
            </div>
            <div className="col-2 col-lx-1 ps-2 d-flex align-items-center">
                {data?.current[index]?.ordersCount}
            </div>
        </div>
    )

    const isPrevExist = (currentUser) => {
        return !!(data?.previous?.find(item => item?.userId === currentUser?.userId)?.ordersCount);
    }

    const handleMouseLeave = (event) => {
        if (containerRef === null
            || containerRef.current === null
            || event.target !== containerRef.current)
            return setTooltip(null);
    }

    useEffect(() => {
        window.addEventListener('mouseout', handleMouseLeave);
        return () => {
            window.removeEventListener('mouseout', handleMouseLeave);
        }
    }, [])

    return (
        <div className="bc-white p-4 rounded-4 mt-4 users-table"
            ref={containerRef}
            style={{ height: "335px" }}>
            {
                !isLoading &&
                <>
                    <span className="fw-semi-bold c-black-100">{t("total_active_users")}</span>
                    <div className={`mt-3 ${ data?.current?.length !== 0 && "pe-4"} `}>
                        <div className="text-sm">
                            <div className="row fw-bold c-black-100 g-0 pb-2 pe-2">
                                <div className="col-7 d-lg-none">{t("name_and_user_name")}</div>
                                <div className="col-3 col-xl-4 d-none d-lg-flex">{t("user_name")}</div>
                                <div className={`col-4 d-none d-lg-flex ${data?.current?.length > TABLE_CAPACITY ? 'ps-1' : 'ps-2'}`}>{t("name")}</div>
                                <div className={`col-3 col-xl-2 ps-3 ${data?.current?.length > TABLE_CAPACITY ? 'ps-md-2' : 'ps-md-3'}`}>{t("spent")}&nbsp;$</div>
                                <div className={`col-2 col-lx-1  
                                ${window.localStorage.getItem(USER_LANGUAGE_KEY) !== FR_LANGUAGE_KEY
                                        ? data?.current?.length > TABLE_CAPACITY ? 'ps-3' : 'ps-4'
                                        : 'ps-3'}`}
                                >{t("orders")}</div>
                            </div>

                            {data?.current?.length > 0 &&
                                <div className="row" style={{ height: '240px', position: 'relative' }}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                className="fw-light c-grey-800"
                                                height={height}
                                                itemCount={data?.current?.length}
                                                itemSize={36}
                                                width={width}>
                                                {UserRow}
                                            </List>
                                        )}
                                    </AutoSizer>
                                    {tooltip && createPortal(tooltip, document.body)}
                                </div>
                            }

                            {data?.current?.length === 0 &&
                                <div className="mt-2">
                                    <div className="border-top empty-row" />
                                    <div className="border-top empty-row" />
                                    <div className="border-top empty-row" />
                                    <div className="border-top empty-row" />
                                    <div className="border-top empty-row" />
                                    <div className="border-top empty-row" />
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
            {
                isLoading &&
                <>
                    <div className="row overflow-hidden">
                        <div className="col-12 placeholder-glow">
                            <div className="placeholder c-grey-300 rounded-4 w-25 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75 px-3"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-25 px-4"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 px-4"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 px-4"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 px-4"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-25 px-4"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 px-4"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 px-5"></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-50 "></div>
                        </div>
                        <div className="col-3 placeholder-glow mb-4">
                            <div className="placeholder c-grey-300 rounded-4 w-75 px-4"></div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default UsersTable;