const Colors = () => {
    return (
        <div className="row">
            <div className="col-12">
                <h1 className="my-4 c-yellow">Colors</h1>
                <div className="row">
                    <div className="col-2">
                        <span>DCM Dark Blue</span>
                        <div className="bc-dark-blue rounded w-75" style={{ height: 50 }}></div>
                    </div>

                    <div className="col-2">
                        <span>DCM Yellow</span>
                        <div className="bc-yellow rounded w-75" style={{ height: 50 }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Colors;